
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "../../store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import router from "../.././router";
import * as Yup from "yup";
import { ElNotification } from "element-plus";
import store from "@/store";
import { Auth } from "aws-amplify";
import { PASSWORD } from "@/helper/globalConst";

export default defineComponent({
  name: "forgot-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
  
    return {
      email: "",
      password: "",
      otp:'',
      loading:false,
      confirmpassword:""
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email("Email should contain @ and . or invalid email address").required().label("Email"),
      // password: Yup.string().required().label("Current password"),
      password: Yup.string()
        .min(8, "Password should have minimum length 8")
        .matches(
          PASSWORD,
          "Password should have minimum length 8 including atleast one Uppercase, One Lowercase, One Number [0-9] and atleast one special case Character @ $ % & ! *?#"
        )
        .required()
        .label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required("Field is required")
        .oneOf([Yup.ref("password"), null], "Password must match")
        .label("Password Confirmation"),

    });

    //Form submit function
    // const onSubmitLogin = (values) => {
    //   // Clear existing errors
    //   store.dispatch(Actions.LOGOUT);

    //   if (submitButton.value) {
    //     // Activate indicator
    //     submitButton.value.setAttribute("data-kt-indicator", "on");
    //   }

    //   // Dummy delay
    //   setTimeout(() => {
    //     // Send login request
    //     console.log("Response")
    //     // store
    //     //   .dispatch(Actions.LOGIN, values)
    //     //   .then(() => {
    //     //     // store.getters.isAuthenticated = true;
    //     //     // Swal.fire({
    //     //     //     text: "You have logged in successfully!!",
    //     //     //     icon: "success",
    //     //     //     buttonsStyling: false,
    //     //     //     confirmButtonText: "Ok, got it!",
    //     //     //     customClass: {
    //     //     //       confirmButton: "btn fw-bold btn-light-primary",
    //     //     //     },

    //     //     // Swal.fire({
    //     //     //   text: "All is cool! Now you submit this form",
    //     //     //   icon: "success",
    //     //     //   buttonsStyling: false,
    //     //     //   confirmButtonText: "Ok, got it!",
    //     //     //   customClass: {
    //     //     //     confirmButton: "btn fw-bold btn-light-primary",
    //     //     //   },
    //     //     // }).then(function () {
    //     //     // Go to page after successfully login
    //     //     router.push({ name: "dashboard" });
    //     //     // });
    //     //   }) 
    //     //   .catch(() => {
    //     //          ElNotification({
    //     //     title: 'Error',
    //     //     message: store.getters.getErrors[0],
    //     //     type: 'error',
    //     //     duration: 2000,
    //     //     position: 'top-right'
    //     //   })
    //     //   });

    //     //Deactivate indicator
    //     submitButton.value?.removeAttribute("data-kt-indicator");
    //   }, 20);
    // };

    return {
      // onSubmitLogin,
      login,
      submitButton,
    };
  },
  mounted()
  {
      let otpa=this.$route.query.confirmation_code == undefined ? "" : this.$route.query.confirmation_code.toString()
      this.otp=otpa
      let emaila=this.$route.query.user_name == undefined ? "" : this.$route.query.user_name.toString()
      this.email=emaila
  },
  methods: {
    async onSubmitlogin() {
      this.loading=true
      // let values = {email: this.email,password:this.password}

      Auth.forgotPasswordSubmit(this.email, this.otp,this.confirmpassword)
    .then(()=> {
            this.loading=false;
                ElNotification({
            title: 'Success',
            message: 'Password Successfully set,Please login ',
            type: 'success',
            duration: 2000,
            position: 'top-right'
          })

            router.push({ name: "sign-in" });
            // });
          }) 
          .catch((err) => {
            // console.log("ERR",err.message)
              this.loading=false;
                 ElNotification({
            title: 'Error',
            message: err.message,
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
          router.push({ name: "sign-in" });
          });
    
    },
  },
});
