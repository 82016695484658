// const PHONE =  /^\d{10}$/;
const PHONE = /^[0-9]{1,50}$/
const GST = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
const COUNTRYCODE = /^[0-9]{1,50}$/;
const ZIPCODE = /^[0-9]{1,50}$/;
const STATE = /^([a-zA-Z/\\&-\s]{2,255})$/;
const CITY = /^([a-zA-Z/\\&-\s]{2,255})$/;
const ADDRESS1 = /^([a-zA-Z0-9/\\'',-.\s]{2,255})$/;
const ADDRESS2 = /^([a-zA-Z0-9/\\'',-.\s]{0,255})$/;
const COB = /^[A-Z]{1,3}$/;
const CIN = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/
const PAN = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
const DSC = /^[a-zA-Z0-9]+$/;
const COMPANYNAME = /^([a-zA-Z0-9/\\.\s]{2,255})$/;
const INDUSTRY = /^([a-zA-Z0-9/\\.\s]{2,255})$/;
const ID = /^[A-Za-z0-9]{1,50}$/;
const NAME = /^([a-zA-Z/\\.\s]{1,255})$/;
const PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/;
const PORT = /^[^-\s][0-9]{0,8}$/;
const HOST = /^[^-\s][a-zA-Z0-9_.\d@$!%*#?&/\\-]{4,}$/;
const CONFIGUREPASSWORD=/^[^-\s][a-zA-Z0-9_.\d@$!%*#?&+=/\\-]{1,}$/;
const USERNAME=/^[^-\s][a-zA-Z0-9_.\d@$!%*#?&+=/\\-]{4,}$/;
const API = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
const FROM = /^[^-\s][a-zA-Z0-9_.\d@$!%*#?&/\\-]{0,}$/;
const APIKEY=/^[^-\s][a-zA-Z0-9_.\d@$!%*#?&+=/\\-]{4,}$/;
const POSTURL=/^[^-\s][a-zA-Z0-9_.\d@$!%*#?&+=/\\-]{0,}$/;
const TEMPLATEID = /^[^-\s][a-zA-Z0-9_.\d@$!%*#?&/\\-]{0,}$/;
const DOMAIN = /^(?:[a-z0-9-]{1,63}\.)+[a-z]{2,6}$/;
export {API, FROM, APIKEY, POSTURL,PORT, HOST,TEMPLATEID, CONFIGUREPASSWORD, USERNAME, DSC, INDUSTRY, PHONE, GST, COUNTRYCODE, ZIPCODE, STATE, CITY, ADDRESS1, ADDRESS2, COB, CIN, PAN, COMPANYNAME, ID, NAME, PASSWORD,DOMAIN};
